import React from "react";
import SmallLogoWhite from "../../assets/small_logo_white.svg";

interface Props {
  className?: any;
}

const Logo: React.FC<Props> = (props: Props) => {
  return <img className={props.className} src={SmallLogoWhite} alt="Logo" />;
};

export { Logo };
