import { Timeline } from "antd";
import React from "react";

export interface TimelineProps {
  dot?: any;
  children?: any;
}

const TimelineItem: React.FC<TimelineProps> = (props: TimelineProps) => {
  return <Timeline.Item dot={props.dot}>{props.children}</Timeline.Item>;
};

export { TimelineItem };
