import React from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";

interface Props {
  timelineProgress: 1 | 2 | 3 | 4 | 5;
}

const useStyles = createUseStyles({
  timeline: {
    margin: "18px 10px 35px 10px",
    height: 7,
    backgroundColor: "#ccc",
    maxWidth: 356,
  },
  timelineProgress: {
    width: (progressBarWidth: number) => {
      return progressBarWidth + "%";
    },
    height: "100%",
    backgroundColor: "#F1D0B2",
  },
  timelineItems: {
    marginLeft: -10,
    marginRight: -10,
    marginTop: -24,
    display: "flex",
    justifyContent: "space-between",
  },
  timelineItem: {
    position: "relative",
    "&::before": {
      content: '""',
      width: 40,
      height: 40,
      backgroundColor: "#ccc",
      display: "block",
      borderRadius: "100%",
    },
  },
  timelineContent: {
    position: "absolute",
    fontSize: 12,
    lineHeight: "17px",
    left: "50%",
    transform: "translateX(-50%)",
    width: 100,
    padding: "4px 10px",
    borderRadius: "5px",
    textAlign: "center",
  },
  active: {
    color: "#1B2E32",
    "&::before": {
      backgroundColor: "#F1D0B2",
    },
  },
  timelineHeader: {
    position: "absolute",
    top: 10,
    left: 16,
    fontSize: 14,
    lineHeight: "20px",
  },
});

const CartTimeline: React.FC<Props> = (props) => {
  const items = [
    {
      name: "Cart",
      active: true,
      title: 1,
    },
    {
      name: "Confirm",
      active: props.timelineProgress > 1 ? true : false,
      title: 2,
    },
    {
      name: "Payment",
      active: props.timelineProgress > 2 ? true : false,
      title: 3,
    },
    {
      name: "Done",
      active: props.timelineProgress > 3 ? true : false,
      title: 4,
    },
  ];

  const totalItems = items.length;
  const numberOfActiveItems = items.filter((item) => item.active).length;
  const progressBarWidth =
    totalItems > 1 ? ((numberOfActiveItems - 1) / (totalItems - 1)) * 100 : 0;
  const classes = useStyles(progressBarWidth);
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.timeline}>
        <div className={classes.timelineProgress} />
        <div className={classes.timelineItems}>
          {items.map((item, i) => (
            <div
              key={i}
              className={`${classes.timelineItem}  ${
                item.active && classes.active
              }`}
            >
              <div className={classes.timelineHeader}> {item.title}</div>
              <div className={classes.timelineContent}>{t(item.name)}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export { CartTimeline };
