import { Divider as DividerComponent } from "antd";
import React from "react";
type DividerType = "horizontal" | "vertical";

export interface DividerProps {
  className?: any;
  dashed?: boolean;
  style?: React.CSSProperties;
  orientation?: "left" | "right" | "center";
  type?: DividerType;
  plain?: boolean;
}

const Divider: React.FC<DividerProps> = ({ className, ...props }) => {
  return <DividerComponent className={`${className}`} {...props} />;
};

export { Divider };
