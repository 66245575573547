import React from "react";
import { createUseStyles } from "react-jss";
import { Typography } from "shared";
import { ContactCall } from "../ContactCall";
import { ContactEmail } from "../ContactEmail";
import { useTranslation } from "react-i18next";
import LineButton from "../LineButton";

const useStyles = createUseStyles({
  contactWrapper: {
    display: "block",
    "@media(max-width: 768px)": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
    },
  },
  title: {
    "@media(max-width: 768px)": {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
    },
  },
  detailWrapper: {
    display: "flex",
    flexDirection: "column",
    "@media(max-width: 768px)": {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "@media(max-width: 769px)": {
      display: "grid",
      gridTemplateColumns: "1fr  ",
      gridGap: "40px",
    },
    "@media(max-width: 469px)": {
      gridGap: "16px",
    },
  },
  contactHead: {
    "@media(max-width: 768px)": {
      marginBottom: 22,
      fontSize: "16px",
      lineHeight: "23px",
    },
    marginBottom: 5,
    fontSize: "24px",
    lineHeight: "36px",
    color: "#f2f2f2",
  },
  vGapper: {
    "@media(min-width: 769px)": {
      marginBottom: 15,
      marginRight: 0,
    },
    "@media(min-width: 426px)": {
      marginRight: 0,
    },
    "@media(max-width: 769px)": {
      marginBottom: 0,
      marginRight: 0,
    },
  },
  allDay: {
    color: "#f2f2f2",
    fontSize: "16px",
    fontWeight: "400",
    paddingTop: 10,
    lineHeight: "36px",
    "@media(max-width: 769px)": {
      fontSize: "12px",
      lineHeight: "12px",
    },
  },
  emailHolder: {
    "@media(max-width: 769px)": {
      marginTop: 0,
    },
  },
  line: {
    width: "55%",

    height: "55px",
    marginTop: 20,
    "@media(max-width: 769px)": {
      marginTop: 0,
      maxWidth: 210,
      width: "75%",
    },
    "@media(max-width: 469px)": {
      height: "40px",
    },
  },
  lineWrapper: {
    marginRight: 15,
    display: "flex",
    paddingTop: 15,
    "& :hover": {
      backgroundColor: "#169e3c",
    },
    "@media(max-width: 752px)": {
      paddingTop: 0,
      display: "block",
    },
    "& iframe": {
      fontWeight: "bold",
      height: "30px !important",
      width: "100px !important",
      paddingTop: 5,
      backgroundColor: "#00b833",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      "& :hover": {
        backgroundColor: "#169e3c",
      },
    },
    "& > span": {
      color: "#f2f2f2",
      fontSize: "16px",
      marginLeft: 10,
      paddingBottom: 5,
      fontWeight: "400",
      paddingTop: 5,
      "@media(max-width: 965px)": {
        fontSize: "14px",
      },
      "@media(max-width: 752px)": {
        fontSize: "12px",
        marginLeft: 0,
      },
    },
  },
});

const CustomerSupport: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.contactWrapper}>
      <div className={classes.title}>
        <Typography className={classes.contactHead}>
          {t("Customer Support")}
        </Typography>
      </div>
      <div className={classes.detailWrapper}>
        <div className={classes.vGapper}>
          <ContactCall
            theme="dark"
            extraText={t("In hurry?-Support 24 hours")}
            header={false}
            phoneNumber={"080-3578-9185"}
          />
        </div>
        <div className={classes.vGapper}>
          <ContactCall
            theme="dark"
            header={true}
            phoneNumber={"049-293-4004"}
          />
          <strong className={classes.allDay}>
            {t("10:00-18:00 Sunday & Holidays closed")}
          </strong>
        </div>
        <div className={classes.emailHolder}>
          <ContactEmail theme="dark" extraText={true} />
        </div>
        <div className={classes.lineWrapper}>
          {/* <div
            className="line-it-button"
            data-lang="ja"
            data-type="friend"
            data-lineid="@700jkjfc"
            style={{ display: "none" }}
          ></div> */}
          <LineButton />
          <span>Lineで問い合わせ (写真共有可能)</span>
        </div>
      </div>
    </div>
  );
};

export { CustomerSupport };
