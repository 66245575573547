import React from "react";
import { useTranslation } from "react-i18next";
import { Card as CardComponent } from "antd";
import { createUseStyles } from "react-jss";
import { Button } from "../Button";
interface Props {
  hoverable?: boolean;
  image: string;
  alt?: string;
  className?: any;
  onClick?: any;
  loading: any;
}
const useStyles = createUseStyles({
  image: {
    border: "none",
    "@media(max-width: 768px)": {
      height: 213,
      width: "100%",
    },
    width: 225,
    height: 364,
    backgroundPosition: "center",
    "& .ant-card-cover": {
      height: "100%",
      width: 225,
      "@media(max-width: 768px)": {
        width: "100%",
      },
    },
    "& img": {
      height: "100%",
      width: 225,
      "@media(max-width: 768px)": { width: 132 },
    },
  },
  overlay: {
    height: 364,
    width: 225,
    background: "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))",
    position: "absolute",
    top: 0,
    left: -1,
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    display: "none",
    "&:hover": {
      display: "flex",
    },
    "@media(max-width: 768px)": {
      height: "100%",
      width: "100%",
      "&:hover": {
        display: "none",
      },
      "&:focus": {
        display: "none",
      },
      "&:active": {
        display: "none",
      },
    },
  },
  actualImageWrapper: {
    height: 364,
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "contain !important",
    backgroundPosition: "center !important",
    "&:hover > div": {
      display: "flex",
    },
    "@media(max-width: 768px)": { height: 213 },
  },
  button: {
    background: "#9C27B0",
    borderColor: "#9C27B0",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      background: "#9C27B0",
      color: "#fff",
    },

    width: 112,
    "@media(max-width: 768px)": {
      borderRadius: 10,
      width: 82,
      padding: "10px 21px",
    },
    "& >span": {
      fontSize: 12,
      lineHeight: "18px",
      "@media(min-width: 769px)": {
        fontSize: 14,
        lineHeight: "21px",
      },
    },
  },
});
const Card: React.FC<Props> = (props: Props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();

  return (
    <CardComponent
      hoverable={props.hoverable}
      className={`${classes.image} ${props.className}`}
      onClick={props.onClick}
      cover={
        <div
          style={{
            background: `url(${props.image})`,
          }}
          className={classes.actualImageWrapper}
        >
          <div className={classes.overlay}>
            <Button
              type="default"
              className={classes.button}
              loading={props.loading}
            >
              {t("Details")}
            </Button>
          </div>
        </div>
      }
    />
  );
};
export { Card };
