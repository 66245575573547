import React, { useEffect } from "react";
import Script from "next/script";

declare global {
  interface Window {
    LineIt?: any;
  }
}

const LineButton = () => {
  useEffect(() => {
    if (typeof window !== "undefined" && window.LineIt) {
      window.LineIt.loadButton();
    }
  }, []);

  return (
    <>
      <div
        className="line-it-button"
        data-lang="ja"
        data-type="friend"
        data-lineid="@700jkjfc"
        style={{ display: "none" }}
      ></div>
      <Script
        src="https://www.line-website.com/social-plugins/js/thirdparty/loader.min.js"
        strategy="lazyOnload"
        onLoad={() => {
          if (typeof window !== "undefined" && window.LineIt) {
            window.LineIt.loadButton();
          }
        }}
      />
    </>
  );
};

export default LineButton;
