const truncateText = (text: string, limit = 10): string => {
  if (text === null || text === undefined) return "";
  const characters: string[] = [...text];

  if (characters.length > limit) {
    return characters.slice(0, limit).join("") + "...";
  }

  return text;
};

export { truncateText };
