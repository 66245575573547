import React from "react";
import { createUseStyles } from "react-jss";
import FullLogo from "../../assets/full_logo.svg";
import SmallFullLogoWhite from "../../assets/small_full_logo_header_white.svg";
import SmallFullLogo from "../../assets/small_full_logo_header.svg";

const useStyle = createUseStyles({
  logoImage: { cursor: "pointer" },
});

interface Props {
  theme: "white" | "dark";
  size?: "small" | "normal";
}

const LogoHeader: React.FC<Props> = (props: Props) => {
  const classes = useStyle();
  return (
    <img
      className={classes.logoImage}
      src={
        props.theme === "dark"
          ? SmallFullLogoWhite
          : props.size === "small"
          ? SmallFullLogo
          : FullLogo
      }
    />
  );
};

export { LogoHeader };
