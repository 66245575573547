import { Col, Layout, Row } from "antd";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Divider, firebase, Logo, LogoHeader, Typography } from "shared";
import HeaderMenuToggler from "../../../../public/assets/headerMenuToggler.svg";
import { AuthContext } from "../../../pages/_app";
import { CartMenu } from "../../molecules/CartMenu";
import { HeaderContact } from "../../molecules/HeaderContact";
import { HeaderMenu } from "../../molecules/HeaderMenu";
import { Login } from "../../molecules/LogIn";
import { StatusBar } from "../../molecules/StatusBar";
import { UserMenu } from "../../molecules/User";

const useStyles = createUseStyles({
  rightMenu: {
    display: "flex",
  },
  eventImage: {
    cursor: "pointer",
    filter: `brightness(105%) contrast(1) blur(0px)`,
  },
  headerLayoutBackground: {
    maxWidth: 1440,
    margin: "0 auto",

    "@media(max-width: 870px)": {
      padding: "0px 28px",
      background: "#1b2e32",
      height: 52,
    },
    padding: "0px ",
    background: "#fff",
    width: "100%",
    height: "auto",
    "@media(max-width: 1570px)": {
      padding: "0px 20px",
    },
  },
  headerWrapper1: {
    "@media(max-width: 870px)": {
      display: "none",
    },
  },
  headerWrapper2: {
    "@media(min-width: 870px)": {
      display: "none",
    },
    display: "flex",
    alignItems: "center",
  },
  logoContactWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 30,
    marginBottom: 5,
  },
  menuBarWrapper: {
    display: "flex",
    marginTop: "37px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  divider: {
    borderBottom: "1px solid #82999B",
    margin: "18px 0px 0px 0px",
  },
  logoClass: {
    width: 27,
    height: 32,
  },
  toggler: {
    width: 22,
    height: 8,
    color: "#f2f2f2",
  },
  rowHeight: { height: 52 },
  rowClass: { width: "100%" },
  logoWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  toggleWrap: {
    display: "flex",
    alignItems: "center",
  },
  contactWrap: {
    paddingTop: 17,
    cursor: "pointer",
  },
  contactText: {
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "right",
  },
  marginRight1: { marginRight: 10 },
  marginRight2: { marginRight: 40 },
});

interface Props {
  onClick: (event?: any) => void;
  cartCounter?: number;
}

const Header: React.FC<Props> = ({ onClick, cartCounter }: Props) => {
  const { Header: AntHeader } = Layout;
  const classes = useStyles();
  const { t } = useTranslation();
  const router = useRouter();
  const contactUsHandler = () => {
    router.push("/contact");
  };
  const { authenticated = false, loading = true } = useContext(AuthContext);
  const [userData, setUserData] = useState(false);
  useEffect(() => {
    setUserData(authenticated);
  }, [authenticated, loading]);
  return (
    <React.Fragment>
      <AntHeader className={classes.headerLayoutBackground}>
        <div className={classes.headerWrapper1}>
          <StatusBar />
          <div className={classes.logoContactWrapper}>
            <div
              onClick={() => {
                if (router.pathname !== "/") router.push("/");
                else window.location.reload();
              }}
            >
              <LogoHeader theme="white" />
            </div>
            <div>
              <HeaderContact />
            </div>
          </div>

          <div className={classes.menuBarWrapper}>
            <HeaderMenu />

            <div className={classes.rightMenu}>
              {userData ? (
                <div
                  className={
                    cartCounter !== 0
                      ? classes.marginRight1
                      : classes.marginRight2
                  }
                >
                  <UserMenu />
                </div>
              ) : (
                !loading && (
                  <div
                    className={
                      cartCounter !== 0
                        ? classes.marginRight1
                        : classes.marginRight2
                    }
                    onClick={() => firebase.auth().signOut()}
                  >
                    <Login />
                  </div>
                )
              )}

              <CartMenu
                counter={cartCounter}
                theme="white"
                onClick={() => null}
              />
            </div>
          </div>
          <Divider className={classes.divider} />
        </div>

        <div className={classes.headerWrapper2}>
          <Row className={`${classes.rowHeight} ${classes.rowClass}`}>
            <Col
              className={`${classes.rowHeight} ${classes.toggleWrap}`}
              span={8}
            >
              <img src={HeaderMenuToggler} alt="Toggle" onClick={onClick} />
            </Col>
            <Col
              className={`${classes.rowHeight} ${classes.logoWrap}`}
              span={8}
            >
              <div
                onClick={() => {
                  router.push("/");
                }}
              >
                <Logo className={classes.logoClass} />
              </div>
            </Col>
            <Col
              className={classes.contactWrap}
              onClick={contactUsHandler}
              span={8}
            >
              <Typography className={classes.contactText} color="#f2f2f2">
                {t("Contact Us")}
              </Typography>
            </Col>
          </Row>
        </div>
      </AntHeader>
    </React.Fragment>
  );
};

export { Header };
