import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { Typography } from "shared";
import { ContactCall } from "../ContactCall";
import { ContactEmail } from "../ContactEmail";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import LineButton from "../LineButton";

const useStyles = createUseStyles({
  contactWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width: 1200px)": {
      flexDirection: "row",
      alignItems: "baseline",
    },
  },
  container: {
    display: "flex",
    "@media(max-width: 1200px)": {
      flexDirection: "column",
      alignItems: "baseline",
    },
  },
  gapper: {
    marginLeft: 15,
    display: "flex",
    justifyContent: "flex-end",
    "& .vGapper-0-2-29": {
      paddingTop: 0,
    },
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    float: "right",
  },
  textClass1: {
    fontSize: "15px",
    lineHeight: "22px",
    color: "#82999b",
  },
  contactUs: {
    cursor: "pointer",
  },
  lineWrapper: {
    marginRight: 15,
    display: "flex",
    alignItems: "baseline",
    "& :hover": {
      backgroundColor: "#169e3c",
    },
    "& iframe": {
      fontWeight: "bold",
      height: "25px !important",
      paddingTop: 3,
      backgroundColor: "#00b833",
      "& :hover": {
        backgroundColor: "#169e3c",
      },
    },
  },
});

const HeaderContact: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const router = useRouter();
  const onClickContactUs = () => {
    router.push("/contact");
  };

  useEffect(() => {
    if (typeof window !== "undefined" && window.LineIt) {
      window.LineIt.loadButton();
    }
  }, []);

  return (
    <div>
      <div className={classes.contactWrapper}>
        <div className={classes.lineWrapper}>
          <LineButton />
        </div>
        <div onClick={onClickContactUs} className={classes.contactUs}>
          <Typography fontSize="24px" lineHeight="36px" color="#1B2e32">
            {t("Contact Us")}
          </Typography>
        </div>
        <div className={classes.container}>
          <div className={classes.gapper}>
            <ContactEmail theme="white" />
          </div>
          <div className={classes.gapper}>
            <ContactCall
              theme="white"
              header={true}
              phoneNumber={"080-3578-9185"}
            />
          </div>
          <div className={classes.gapper}>
            <ContactCall
              theme="white"
              header={true}
              phoneNumber={"049-293-4004"}
            />
          </div>
        </div>
      </div>
      <div className={classes.textWrapper}>
        <Typography className={classes.textClass1}>
          {t("10:00~18:00 Sunday & Holidays closed")}
        </Typography>
      </div>
    </div>
  );
};

export { HeaderContact };
