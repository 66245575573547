import React from "react";
import { createUseStyles } from "react-jss";
import { Typography } from "shared";
import { useTranslation } from "react-i18next";

const useStyles = createUseStyles({
  linkWrapper: {
    display: "flex",

    "@media(min-width: 769px)": {
      marginTop: 23,
    },
    "@media(max-width: 768px)": {
      justifyContent: "center",
    },
    justifyContent: "flex-end",
  },
  linkGroup1Wrapper: {
    "@media(min-width: 1250px)": {
      marginRight: 1,
    },

    "@media(max-width: 1549px)": {
      marginRight: 28,
      minWidth: "136px",
    },
    marginRight: 58,
  },
  linkGroup2Wrapper: {
    "@media(min-width: 769px)": {
      marginRight: 15,
    },
    "@media(min-width: 1450px)": {
      minWidth: 240,
    },
  },
  linkMargin: {
    "@media(min-width: 769px)": {
      marginBottom: 36,
    },
    marginBottom: 22,
  },
  link: {
    color: "#f2f2f2",
    "@media(min-width: 769px)": {
      textAlign: "right",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "26px",
    },
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "17px",
  },
  specifiedText: {
    "@media(max-width: 769px)": {
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
    },
  },
});

const FooterLinkGroups: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <div className={classes.linkWrapper}>
        <div className={classes.linkGroup1Wrapper}>
          <Typography
            type="link5"
            href="/customer-voice"
            className={`${classes.link} ${classes.linkMargin}`}
          >
            {t("Feedback")}
          </Typography>
          <Typography
            type="link5"
            href="/contact"
            className={`${classes.link} ${classes.linkMargin}`}
          >
            {t("Contact Us")}
          </Typography>
          <Typography
            type="link5"
            href="/request"
            className={`${classes.link} ${classes.linkMargin}`}
          >
            {t("Request")}
          </Typography>
        </div>
        <div className={classes.linkGroup2Wrapper}>
          <Typography
            type="link5"
            href="/carpet-and-interior"
            className={`${classes.link} ${classes.linkMargin}`}
          >
            {t("Carpet and Interior")}
          </Typography>
          <Typography
            type="link5"
            href="/about-us"
            className={`${classes.link} ${classes.linkMargin}`}
          >
            {t("About Golestan")}
          </Typography>
          <Typography
            type="link5"
            href="/carpet-articles"
            className={`${classes.link} ${classes.linkMargin}`}
          >
            {t("Carpet Magazine")}
          </Typography>
          <Typography
            type="link5"
            href="/shopping-guide"
            className={`${classes.link} ${classes.linkMargin}`}
          >
            {t("Shopping Guide")}
          </Typography>
          <Typography
            type="link5"
            href="/guide-info"
            className={`${classes.link} ${classes.linkMargin}`}
          >
            {t("Guide Details")}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export { FooterLinkGroups };
